@import '../../../public/vendor/bower/bootstrap/less/variables';
@import 'variables';
@import 'classes';
@import 'mixins';
//@import '../../vendor/bower/bootstrap/less/bootstrap';
@import '../../../public/vendor/bower/fontawesome/less/font-awesome';
@import (less)'../../../public/vendor/bower/ladda/dist/ladda.min.css';
//@import (less)'../../vendor/bower/ladda/dist/ladda-themeless.min.css';
@import (less) "../../../public/vendor/bower/lightbox/css/lightbox.css";

/* FORMVALIDATOR FEEDBACK CLASSES START*/

.control-error-message{
  color:#a94442;
  font-size: 12px;
  }
.control-warning-message{
  color:#8a6d3b;
  font-size: 12px;
  }

.feedback-icon-input-text .form-control-feedback
{
  right:10px;
  }

.selectpicker-container .form-control-feedback {
  /* Adjust feedback icon position */
  right: -32px;
  }

.top .form-control-feedback {
  /* Adjust feedback icon position */
  top:0px;
  }

.select-container .form-control-feedback {
  /* Adjust feedback icon position */
  right: -32px;
  }

.checkbox-container .form-control-feedback {
  /* Adjust feedback icon position */
  top: -8px;
  }

.hide-validation-icon .form-control-feedback {
  /* Adjust feedback icon position */
  display: none !important;
  }

.asterisk-required-after::after
{
  content:'*';
  color:#b81900;
  }

/* FORMVALIDATOR FEEDBACK CLASSES END*/

/* CSS BASE CLASSES START */

.cursor-pointer
{
  cursor: pointer;
  }

.text-align-center
{
  text-align: center;
  }
.text-align-left
{
  text-align: left;
  }
.text-align-right
{
  text-align: right;
  }
.clear-both
{
  clear:both;
  }

.font-weight-bold{
  font-weight: bold;
  }
.font-weight-bolder{
  font-weight: bolder;
  }

.text-decoration-underline
{
  text-decoration: underline;
  }

.color-blue-hover:hover
{
  color:#3276b1;
  }

.important-margin-top-20
{
  margin-top:20px !important;
  }

.important-margin-bottom-20
{
  margin-bottom:20px !important;
  }

.important-margin-bottom-40
{
  margin-bottom:40px !important;
  }

.important-margin-top-10
{
  margin-top:10px !important;
  }

.important-margin-bottom-10
{
  margin-bottom:10px !important;
  }

.important-margin-top-bottom-10
{
  margin-top:10px !important;
  margin-bottom:10px !important;
  }

.important-margin-top-0
{
  margin-top:0px !important;
  }

.important-margin-bottom-0
{
  margin-bottom:0px !important;
  }

.important-margin-top-bottom-0
{
  margin-top:0px !important;
  margin-bottom:0px !important;
  }

.lead-margin-botton-10
{
  margin-bottom: 10px !important;
  }

.lead-margin-top-botton-10
{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  }
/* CSS BASE CLASSES END */