.box-shadow(@style, @c) when (iscolor(@c)) {
  box-shadow:         @style @c;
  -webkit-box-shadow: @style @c;
  -moz-box-shadow:    @style @c;
  }

.margin-bottom-xs {margin-bottom: ceil(@line-height-computed / 4);}
.margin-bottom-sm {margin-bottom: ceil(@line-height-computed / 2);}
.margin-bottom-md {margin-bottom: @line-height-computed;}
.margin-bottom-lg {margin-bottom: ceil(@line-height-computed * 2);}

