@import 'base';
@import "../../../public/vendor/smart_admin/COMMON_ASSETS/LESS_FILES/bootstrap";
@import "../../../public/vendor/smart_admin/COMMON_ASSETS/LESS_FILES/smartadmin-production-plugins";
@import "../../../public/vendor/smart_admin/COMMON_ASSETS/LESS_FILES/smartadmin-production";
@import (less) "../../../public/vendor/smart_admin/css/smartadmin-skins.min.css";
@import (less) "../../../public/vendor/smart_admin/css/smartadmin-rtl.min.css";
@import (less) "../../../public/vendor/bower/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
@import (less) "../../../public/vendor/fileinput/fileinput.css";
@import (less) "../../../public/vendor/formcontrol/formcontrol.css";
@import (less) "../../../public/vendor/bower/mjolnic-bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css";
@import (less) "../../../public/vendor/bower/bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css";

/* START ADMIN THEMA OVERWRITENS */

.flotTip {
  padding               : 3px 5px;
  background-color      : #fff;
  z-index               : 9999;
  color                 : #333;
  -webkit-box-shadow    : 0 1px 8px rgba(0, 0, 0, .1);
  -moz-box-shadow       : 0 1px 8px rgba(0, 0, 0, .1);
  box-shadow            : 0 1px 8px rgba(0, 0, 0, .1);
  font-size             : 14px;
  border                : 1px solid #C1C1C1;
  -moz-border-radius    : 4px;
  -khtml-border-radius  : 4px;
  -webkit-border-radius : 4px;
  border-radius         : 4px
  }

.flotTip span {
  color       : #38812D;
  font-weight : 700
  }


.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 4px 5px;
  }

div.dataTables_info {
  padding-top: 9px;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  color: #969696;
  }

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 5px 0;
  border-radius: 2px;
  }

.dataTables_filter .input-group-addon{
  width: 24px;
  margin-top: 0px;
  float: left;
  height: 24px;
  padding: 4px;
  }

.input-group-addon-xs {
  width: 24px;
  margin-top: 0px;
  height: 24px;
  padding: 4px;
  }

.pagination-sm > li > a, .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 10px;
  }

.popover{
  min-width: 160px;
  }

.icon-addon .form-control:focus + .form-control-feedback.glyphicon-remove,
.icon-addon:hover .form-control-feedback.glyphicon-remove,
.icon-addon .form-control-feedback.glyphicon-remove{
  color: #b94a48 !important;
  }
.icon-addon .form-control:focus + .form-control-feedback.glyphicon-remove,
.icon-addon:hover .form-control-feedback.glyphicon-ok,
.icon-addon .form-control-feedback.glyphicon-ok{
  color: #468847 !important;
  }

.help-block{
  color: #b94a48 !important;
  }

.header-dropdown-list {
  margin: 0px 0px 0px 10px;
  }


.dataTables_length
{
  float:right;
  }

.dt-toolbar>:last-child
{
  padding-right: 0!important;

  }

table.dataTable thead > tr > th input.date_range_filter
{
  width: 95px !important;
  display:  inline;
  }
.table-options-btns {
  list-style-type : none;
  margin          : 0;
  display         : inline-flex;
  padding         : 0;
  }
.table-options-btns li:first-child
{
  margin-left: 0px !important;
  }
.table-options-btns li
{
  margin-left: 10px;
  }

/*#loader-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 40000;
  text-align: center;
  background: rgba(255,255,255,0.75) url(../img/backend/loading-anim.gif) center center no-repeat;
  }*/

.loading-wrapper, .errors-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20000;
  background: #0096d6;
  /* The Fallback */
  background: rgba(0, 150, 214, 0.9);
}
.loading-wrapper.loading-wrapper-white, .errors-wrapper.errors-wrapper-white {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20000;
  background: #ffffff;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8);
}
.loading-wrapper .loading, .errors-wrapper .error-div {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #3a3633;
  margin: -60px 0 0 -60px;
  background: #fff;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  border: 10px solid #3a3633;
  text-align: center;
  padding-top: 40px;
}
.errors-wrapper .error-div {
  margin: -100px 0 0 -100px;
  width: 200px;
  height: 200px;
  padding-top: 30px;
  color: #a90329;
}
.loading-wrapper .loading:after {
  content: '';
  background: trasparent;
  width: 140%;
  height: 140%;
  position: absolute;
  border-radius: 100%;
  top: -20%;
  left: -20%;
  opacity: 0.7;
  box-shadow: rgba(255, 255, 255, 0.6) -4px -5px 3px -3px;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading-wrapper.loading-wrapper-white .loading:after, .errors-wrapper.errors-wrapper-white .error-div:after {
  box-shadow: rgba(58, 54, 51, 0.6) -4px -5px 3px -3px;
}
.modal_dialog_fullscreen .modal-dialog{
  width:100%;
  height:100%;
  margin: auto;
  }

.modal-dialog .modal-header, .modal-dialog .modal-footer
{
  background: url('../img/backend/mybg.png') #ffffff;

  }


/* END ADMIN THEMA OVERWRITENS */

/* START ORDER EDIT VIEW */

.sc-head-menu-el {
  margin-top    : 30px;
  margin-bottom : 30px;
  color         : #838383;
  font-size     : 16px;
  position      : relative;
  }

.sc-head-menu-el.active {
  color       : #292929;
  font-weight : bold;
  }

.sc-head-menu-el span {
  padding               : 9px 16px;
  -webkit-border-radius : 40px;
  -moz-border-radius    : 40px;
  border-radius         : 40px;
  background            : #838383;
  margin-right          : 10px;
  color                 : #fff;
  transition            : all 0.30s ease-out;
  -o-transition         : all 0.30s ease-out;
  -moz-transition       : all 0.30s ease-out;
  -webkit-transition    : all 0.30s ease-out;
  font-weight           : normal;
  }

.sc-head-menu-el.active span {
  background : #3cabd8;
  }

.sc-head-menu-el .arrow {
  display            : none;
  position           : absolute;
  bottom             : -30px;
  left               : 25px;
  border-left        : 10px solid transparent;
  border-right       : 10px solid transparent;
  border-bottom      : 10px solid #3cabd8;
  transition         : all 0.40s ease-out;
  -o-transition      : all 0.40s ease-out;
  -moz-transition    : all 0.40s ease-out;
  -webkit-transition : all 0.40s ease-out;
  width              : 0;
  height             : 0;
  }

.sc-head-menu-el.active .arrow {
  display : block;
  }

.sc-head-order-number {
  color            : #ffffff;
  background-color : #3cabd8;
  font-size        : 12px;
  border           : none;
  }
.sc-head-order-number td {
  font-size: 14px;
  padding: 6px;
  }

.sc-product-head {
  background-color : #f9f9f9;
  text-transform   : uppercase;
  font-size        : 12px;
  border           : none;
  }

.sc-product-head td, .sc-product-container td {
  }

.sc-product-head td {
  }

.sc-product-head td:first-child {
  background-color : #56606e;
  color            : #ffffff;

  }

.sc-product-container {
  font-size : 12px;

  }

.sc-product-container td {
  vertical-align : middle !important;
  }

.sc-product-container td:first-child {
  background-color : #56606e;
  color            : #ffffff;

  }

.sc-product-container td input {
  width      : 50px;
  height     : 24px;
  font-size  : 12px;
  padding    : 1px;
  margin     : 0px;
  text-align : center;
  }

.sc-product-container td.count {
  background-color : #ffffef;

  }

.sc-bordered-table {
  margin-bottom : 0px !important;
  }

.sc-bordered-table tr td {
  border : 1px solid #eaeaea;
  }

.sc-bordered-table tr:first-child td {
  border-top : none;
  }

.sc-bordered-table tr td.position {
  min-width : 60px;
  width     : 60px;
  }

.sc-product-info-container {
  padding   : 0;
  overflow  : hidden;
  font-size : 12px;
  }

.sc-product-info-container, .sc-product-img-container, .sc-product-btns-container {
  margin-top    : 20px;
  margin-bottom : 20px;
  }

.sc-product-img-container {
  position : relative;
  }
.sc-product-img-container i {
  position : absolute;
  top: 4px;
  left: 19px;
  }
.sc-product-img-container .fa-search-plus
{
  z-index: 999;
  }
.sc-product-img-container .fa-search-minus
{
  z-index: 1001;
  display:none;
  }

.sc-product-img-container .img-thumb {
  max-width : 200px;
  cursor: pointer;
  }

.sc-product-img-container .zoomed-img {
  position : absolute;
  top      : 0px;
  left     : 15px;
  z-index  : 1000;
  display  : none;
  max-width: 200px;
  background         : none repeat scroll 0 0 #ffffff;
  -moz-box-shadow    : 0px 1px 7px #b8b5b8;
  -webkit-box-shadow : 0px 1px 7px #b8b5b8;
  box-shadow         : 0px 1px 7px #b8b5b8;
  cursor: pointer;
  }

.sc-product-info-container .left {
  display       : block;
  float         : left;
  width         : 100px;
  padding-right : 7px;
  margin-bottom : 5px;
  }

.sc-product-info-container .right {
  display       : block;
  float         : left;
  padding-left  : 17px;
  margin-bottom : 5px;
  }

.sc-backgraunded-table {
  background-color : #f9f9f9;
  }

.sc-calc-price-container, .sc-btns-container {
  border-bottom : 1px solid #ededed;
  border-left   : 1px solid #ededed;
  border-right  : 1px solid #ededed;
  margin-left   : 0px;
  margin-right  : 0px;

  }

.sc-btns-container {
  padding-top    : 15px;
  padding-bottom : 15px;
  }

.sc-calc-price-container .total-price {
  font-size : 18px;
  }

.sc-calc-price-container .price-text {
  padding    : 20px 0px;
  text-align : right;
  }

.sc-calc-price-container .help-text {
  background-color : #fffb98;
  color            : #6b4523;
  font-size        : 14px;
  padding          : 10px;
  }

.sc-product-btns-container a {
  margin : 10px 0px;
  }

.table-status {
  text-align : left;
  }

.table-status tr td {
  border-top     : none !important;
  padding-top    : 4px !important;
  padding-bottom : 4px !important;
  }

.table-status .finestre-logo {
  max-height : 35px;
  }

.table-status .logo-telefon {
  margin-top : 3px;
  }
.table-for-print{
  display: none;
  }
.label
{
  font-size: 85%;
  }

/* END ORDER EDIT VIEW */

/* START CUSTOM CLASSES */

.text-color-red{
  color: #c26565;
  }

.head-btns{
  margin-bottom: 10px;
  }

.tr_blue
{
  border-bottom: solid 1px #DDDDDD;
  border-right: solid 1px #DDDDDD;
  background: #AADDFF !important;
  }

.tr_red
{
  border-bottom: solid 1px #DDDDDD;
  border-right: solid 1px #DDDDDD;
  background: #F06566 !important;
  }

.tr_orange
{
  border-bottom: solid 1px #DDDDDD;
  border-right: solid 1px #DDDDDD;
  background: #FF7E47 !important;
  }

/* ENDCUSTOM CLASSES */


/* BOOTBOX */

.bootbox
{
  background:rgba(255,255,255,0.8);
  }

/* / BOOTBOX */



/*Label align for checkboxes   
@media (min-width: 992px){
.labelvertalign{
  position: relative;
  top: 25px;
}
}

*/
