	/*

	 Copyright 2013 - SmartAdmin : WebApp (Sunny)
	
	 * This is part of an item on wrapbootstrap.com
	 * https://wrapbootstrap.com/user/myorange
	 * ==================================
	
	 Table of Contents
	 ---------------------------------------------------------------
	
	 - Main
	 - Header
	 - Content
	 - Edit box
	 - Timestamp
	 - Helpers
	 - Fullscreen
	 - Styling
	 - Custom styles
	 - Icons - A few used examples
	 - RTL support
	 - Clearfix
	 - Examples (minimalistic in grid)
	 - Examples (lists)
	
	 */
	
	/*
	 * MAIN
	 */
	
	article.sortable-grid {
	    min-height:30px;
	}
	.jarviswidget {
	    margin: 0 0 30px 0;
	    position: relative;
	    -moz-border-radius: 0px;
	    -khtml-border-radius: 0px;
	    -webkit-border-radius: 0px;
	    border-radius: 0px;
	    padding: 0;
	}
	.widget-content-padding {
	    padding: 20px;
	    .well {
	        margin-bottom: 0px;
	    }
	}

	/*
	 * HEADER
	 */
	
	.jarviswidget {
	    & > header {
	        height: 34px;
	        padding: 0;
	        line-height: 40px;
	        & > .widget-icon {
	            display:block;
	            width: 30px;
	            height: 32px;
	            position:relative;
	            float:left;
	            font-size: 111%;
	            line-height:32px;
	            text-align:center;
	            margin-right:-10px;
	        }
	        h2 {
	            height: 100%;
	            width: auto;
	            display:inline-block;
	            font-size: 14px;
	            position: relative;
	            margin: 0;
	            line-height: 34px;
	            font-weight:normal;
	            letter-spacing: 0px;
	        }
	        & > h2 {
	            margin-left:10px;
	            float:left;
	        }
	        & > :first-child.widget-icon {
	            margin-left:0;
	        }
	        .jarviswidget-icon {
	            width: 16px;
	            height: 16px;
	            float: left;
	            margin: 12px 10px 0 0;
	            display: block;
	            zoom: 1;
	            z-index: 4000;
	        }
	    }
	}
	.ui-sortable {
	    .jarviswidget-sortable {
	        & > header {
	            cursor: move;
	            -moz-border-radius: 0px;
	            -khtml-border-radius: 0px;
	            -webkit-border-radius: 0px;
	            border-radius: 0px;
	            line-height:normal;
	            box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
	            -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
	            -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
	        }
	    }
	    .jarviswidget-sortable.jarviswidget-collapsed {
	        & > header {
	            -moz-border-radius: 0px;
	            -khtml-border-radius: 0px;
	            -webkit-border-radius: 0px;
	            border-radius: 0px;
	        }
	    }
	}
	.jarviswidget-ctrls {
	    width: auto;
	    float: right;
	    padding: 0;
	    margin: 0;
	    .button-icon {
	        min-width: 30px;
	        height: 32px;
	        float: left;
	        position: relative;
	        font-family: Arial, Helvetica, sans-serif;
	        border-left:1px solid rgba(0,0,0,0.09);
	    }
	    .button-icon:hover {
	        background-color:rgba(0,0,0,0.05);
	    }
	}
	.jarviswidget-loader {
	    width: 32px;
	    height: 32px;
	    margin: 0;
	    float: right;
	    background-repeat: no-repeat;
	    background-position: center center;
	    display: none;
	    text-align: center;
	    line-height: 32px;
	    font-size: 111%;
	}

	
	/*
	 * CONTENT
	 */
	
	.jarviswidget {
	    & > div {
	        float: left;
	        width: 100%;
	        position: relative;
	        font-size: 13px;
	        -moz-border-radius: 0px;
	        -khtml-border-radius: 0px;
	        -webkit-border-radius: 0px;
	        border-radius: 0px;
	        margin: 0;
	        border-width: 1px 1px 2px 1px;
	        border-style: solid;
	        border-top: none;
	        border-right-color: #CCC !important;
	        border-bottom-color: #CCC !important;
	        border-left-color: #CCC !important;
	        padding:13px 13px 0px;
	        overflow: @widgetContentOverflow;
	    }
	    .widget-body {
	        min-height:100px;
	        position:relative;
	        padding-bottom:13px;
	    }
	    .widget-body.widget-hide-overflow {
	        overflow:hidden;
	    }
	}
	.widget-toolbar.no-border {
	    border-left:none;
	}
	.widget-body.no-padding {
	    margin:-13px -13px 0px;
	    .alert {
	        margin: 0 0 10px;
	        padding: 10px;
	        box-shadow:none !important;
	        -webkit-box-shadow:none !important;
	        -moz-box-shadow:none !important;
	        border-width:0px 0px 1px !important;
	        border-radius:0px !important;
	        -webkit-border-radius: 0px !important;
	        -moz-border-radius: 0px !important;
	    }
	}
	.jarviswidget.well.transparent {
	    .widget-body.no-padding {
	        margin:0px !important;
	    }
	}
	.widget-body {
	    & > table {
	        margin-bottom:0;
	    }
	}
	.widget-body.no-padding .cke_chrome, .no-padding .md-editor {
	    border:none;
	}
	.widget-toolbar {
	    & > .smart-form {
	        margin-top:2px;
	        .toggle:last-child {
	            font-size:12px;
	            line-height:29px;
	        }
	    }
	}
	.widget-toolbar &>.smart-form .radio input + i,.widget-toolbar &>.smart-form .checkbox input + i,.widget-toolbar &>.smart-form .toggle input + i {
	    border-width:1px;
	    border-color: #C7C7C7 !important;
	    margin-top:1px;
	    box-shadow: 0 1px 1px #FFF,0 1px 1px #858585 inset;
	    -webkit-box-shadow: 0 1px 1px #FFF,0 1px 1px #858585 inset;
	    -moz-box-shadow: 0 1px 1px #FFF,0 1px 1px #858585 inset;
	}


	/*
	 * WELL WIDGET
	 */
	
	.jarviswidget.well {
	    margin: 0 0 30px 0;
	    & > div {
	        border:none !important;
	        box-shadow:none !important;
	        -webkit-box-shadow:none !important;
	        -moz-box-shadow:none !important;
	    }
	    header {
	        display:none;
	    }
	}

	/*
	 * WIDGET FOOTER
	 */
	
	.widget-footer {
	    display: block;
	    min-height: 32px;
	    vertical-align:middle;
	    position: relative;
	    background-color: #F8F7F7;
	    padding: 5px;
	    border-top: 1px solid #E4E4E4;
	    text-align:right;
	    margin: 0px -13px -13px;
	}
	.no-padding {
	    .widget-footer {
	        margin:0;
	    }
	}
	.widget-footer.smart-form {
	    & > label {
	        margin-top:4px;
	        display:block;
	    }
	}

	
	/*
	 * EDIT BOX
	 */
	
	.jarviswidget-editbox {
		display: none;
		padding: 10px;
	}
	/*.jarviswidget-editbox div {
		width: 100%;
		float: left;
		margin-bottom: 0;
	}
	.jarviswidget-editbox label {
		width: 80px;
		height: 26px;
		float: left;
		line-height: 26px;
		font-weight: bold;
		margin-bottom:0;
	}
	.jarviswidget-editbox input {
		width: 200px;
		height: 24px;
		padding: 0 5px;
		border: 1px solid #666;
	}
	.jarviswidget-editbox span {
		height: 26px;
		width: 26px;
		float: left;
		margin: 0 5px 0 0;
		cursor: pointer;
	}*/
	
	/*
	 * TIMESTAMP
	 */
	
	.jarviswidget-timestamp {
		font-size: 12px;
		color: #868686;
		font-style:italic;
		margin: 10px 0 0;
	}
	
	/*
	 * HELPERS
	 */
	
	.jarviswidget-placeholder {
		-moz-border-radius: 0px;
		-khtml-border-radius: 0px;
		-webkit-border-radius: 0px;
		border-radius: 0px;
		margin-bottom: 28px; /* adjusted from 28px */
		padding: 0;
		-webkit-box-sizing: border-box;
		-khtml-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
	}
	
	/*
	 * FULL SCREEN
	 */
	
	body.nooverflow {
	    overflow: hidden;
	    position: fixed;
	    width:100%;
	}
	#jarviswidget-fullscreen-mode {
	    width: 100%;
	    height: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    z-index: @widget-fullscreen-zindex;
	    .jarviswidget {
	        margin: 0;
	        -moz-border-radius: 0;
	        -khtml-border-radius: 0;
	        -webkit-border-radius: 0;
	        border-radius: 0;
	        & > div {
	            overflow-y: scroll;
	            -moz-border-radius: 0;
	            -khtml-border-radius: 0;
	            -webkit-border-radius: 0;
	            border-radius: 0;
	        }
	    }
	    & > div {
	        & > header {
	            cursor: default;
	        }
	    }
	}

	/*
	 * WIDGET BODY TOOLBAR
	 */
	
	.widget-body-toolbar, .smart-form.widget-body-toolbar {
	    display:block;
	    padding:8px 10px;
	    margin:-13px -13px 13px;
	    min-height:42px;
	    border-bottom:1px solid #ccc;
	    background: #fafafa;
	}
	.no-padding.widget-body-toolbar, .no-padding .widget-body-toolbar, .no-padding .smart-form.widget-body-toolbar {
	    display:block;
	    margin:0px;
	}
	.widget-body-toolbar.smart-form .inline-group,.smart-form .widget-body-toolbar .inline-group {
	    float: left;
	    margin-top: 4px;
	}
	.widget-body-toolbar {
	    .btn {
	        vertical-align:middle;
	    }
	    .btn-xs {
	        margin-top:5px;
	    }
	}
	
	/*
	 * WIDGET BODY AJAX LOAD
	 */
	
	.widget-body-ajax-loading::before {
		content: url('@{base-url}/ajax-loader.gif');
		padding-top:18%;
		text-align:center;
		font-weight:bold;
		font-size:16px;
		color:#fff;
		display: block;
		background: rgba(255, 255, 255, 0.4);
		height: 100%;
		z-index: 1;
		width: 100%;
		position: absolute;
	}
	.widget-body-ajax-loading:hover {
		cursor:wait !important;
	}
	
	/*
	 * STYLING
	 * Default
	 */
	

	.jarviswidget {
	    & > header {
	        color: #333;
	        border: 1px solid #C2C2C2;
	        background: #fafafa;
	    }
	    & > div {
	        background-color: #fff !important;
	    }
	}
	.jarviswidget-editbox {
	    border-bottom: 1px solid #B1B1B1;
	    background-color: #fff;
	    margin: -13px -13px 13px;
	}
	.no-padding {
	    .jarviswidget-editbox {
	        margin: 0 0 10px;
	    }
	}
	.jarviswidget-placeholder {
	    background-color: #FFC;
	    border: 1px dashed #A7A7A7;
	}
	
	/*
	 * CUSTOM STYLES
	 */
	
	/* remove colors */
	.jarviswidget-remove-colors {
		color:#333 !important;
		padding:0 !important;
		background:none !important;
	}

	/* magenta theme */
	.jarviswidget-color-magenta>header {
		border-color: darken(@magenta, 3%) !important;
		background: @magenta;
		color:@white;
	}
	
	.jarviswidget-color-magenta &>header &>.jarviswidget-ctrls a,
	.jarviswidget-color-magenta .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-magenta {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}


	/* pink theme */
	.jarviswidget-color-pink>header {
		border-color: darken(@pink, 3%) !important;
		background: @pink;
		color: @white;
	}
	
	.jarviswidget-color-pink &>header &>.jarviswidget-ctrls a, .jarviswidget-color-pink .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-pink {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}

	
	/* pinkDark theme */
	.jarviswidget-color-pinkDark>header {
		border-color: darken(@pinkDark, 3%) !important;
		background: @pinkDark;
		color: @white;
	}
	
	.jarviswidget-color-pinkDark &>header &>.jarviswidget-ctrls a, .jarviswidget-color-pinkDark .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-pinkDark {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}

	
	/* yellow theme */
	.jarviswidget-color-yellow>header {
		border-color: darken(@yellow, 3%) !important;
		background: @yellow;
		color: @white;
	}
	
	.jarviswidget-color-yellow &>header &>.jarviswidget-ctrls a, .jarviswidget-color-yellow .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-yellow {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}
	
	
	/* orange theme */
	.jarviswidget-color-orange>header {
		border-color: darken(@orange, 3%) !important;
		background: @orange;
		color: @white;
	}
	
	.jarviswidget-color-orange &>header &>.jarviswidget-ctrls a, .jarviswidget-color-orange .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-orange {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}

	
	/* orangeDark theme */
	.jarviswidget-color-orangeDark > header {
		border-color: darken(@orange, 5%) !important;
		background: darken(@orange, 4%);
		color: @white;
	}
	
	.jarviswidget-color-orangeDark &>header &>.jarviswidget-ctrls a,.jarviswidget-color-orangeDark .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-orangeDark {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}
	
	/* darken theme */
	.jarviswidget-color-darken > header {
		border-color: darken(@darken, 3%) !important;
		background: @darken;
		color: @white;
	}
	
	.jarviswidget-color-darken &>header &>.jarviswidget-ctrls a,.jarviswidget-color-darken &>.nav-tabs &>li:not(.active) &>a {
	    color:@white !important;
	}
	.jarviswidget-color-darken {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}

	/* purple theme */
	.jarviswidget-color-purple > header {
		border-color: darken(@purple, 3%) !important;
		background: @purple;
		color: @white;
	}
	
	.jarviswidget-color-purple &>header &>.jarviswidget-ctrls a,.jarviswidget-color-purple .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-purple {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}
	
	/* teal theme */
	.jarviswidget-color-teal > header {
		border-color: darken(@teal, 3%) !important;
		background: @teal;
		color: @white;
	}
	
	.jarviswidget-color-teal &>header &>.jarviswidget-ctrls a,.jarviswidget-color-teal .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-teal {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}

	/* teal theme */
	.jarviswidget-color-blueDark > header {
		border-color: darken(@blueDark, 3%) !important;
		background: @blueDark;
		color: @white;
	}
	
	.jarviswidget-color-blueDark &>header &>.jarviswidget-ctrls a,.jarviswidget-color-blueDark .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-blueDark {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}

	/* blue theme */
	.jarviswidget-color-blue > header {
		border-color: darken(@blueDark, 3%) !important;
		background: @blueDark;
		color: @white;
	}
	
	.jarviswidget-color-blue &>header &>.jarviswidget-ctrls a,.jarviswidget-color-blue .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-blue {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}
	
	/* blueLight theme */
	.jarviswidget-color-blueLight > header {
		border-color: lighten(@blueLight, 5%) !important;
		background: lighten(@blueLight, 20%);
		color: @black;
	}
	
	.jarviswidget-color-blueLight &>header &>.jarviswidget-ctrls a,.jarviswidget-color-blueLight .nav-tabs li:not(.active) a {
	    color:#2b363c !important;
	}
	.jarviswidget-color-blueLight {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#2b363c !important;
	            }
	        }
	    }
	}
	
	/* red theme */
	.jarviswidget-color-red > header {
		border-color: darken(@red, 3%) !important;
		background: @red;
		color: @white;
	}
	
	.jarviswidget-color-red &>header &>.jarviswidget-ctrls a,.jarviswidget-color-red .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-red {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}
	
	/* redLight theme */
	.jarviswidget-color-redLight > header {
		border-color: darken(@redLight, 3%) !important;
		background: @redLight;
		color: @white;
	}
	
	.jarviswidget-color-redLight &>header &>.jarviswidget-ctrls a,.jarviswidget-color-redLight .nav-tabs li:not(.active) a {
	    color:#fff !important;
	}
	.jarviswidget-color-redLight {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}

	/* white theme */
	.jarviswidget-color-white > header {
		border-color: darken(@white, 20%) !important;
		background: @white;
		color: darken(@white, 60%);
	}
	
	.jarviswidget-color-white &>header &>.jarviswidget-ctrls a,.jarviswidget-color-white .nav-tabs li:not(.active) a {
	    color:darken(@white, 50%) !important;
	}
	.jarviswidget-color-white {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:darken(@white, 50%) !important;
	            }
	        }
	    }
	}

	/* greenDark theme */
	.jarviswidget-color-greenDark > header {
		border-color: darken(@greenDark, 3%) !important;
		background: @greenDark;
		color: @white;
	}
	
	.jarviswidget-color-greenDark &>header &>.jarviswidget-ctrls a,
	.jarviswidget-color-greenDark .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-greenDark {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}
	
	/* green theme */
	.jarviswidget-color-green > header {
		border-color: darken(@green, 3%) !important;
		background: @green;
		color: @white;
	}
	
	.jarviswidget-color-green &>header &>.jarviswidget-ctrls a, .jarviswidget-color-green .nav-tabs li:not(.active) a {
	    color:@white !important;
	}
	.jarviswidget-color-green {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}
	
	/* greenLight theme */
	.jarviswidget-color-greenLight > header {
		border-color: darken(@greenLight, 3%) !important;
		background: @greenLight;
		color: @white;
	}
	
	.jarviswidget-color-greenLight &>header &>.jarviswidget-ctrls a, .jarviswidget-color-greenLight .nav-tabs li:not(.active) a {
	    color:#fff !important;
	}
	.jarviswidget-color-greenLight {
	    .nav-tabs {
	        li {
	            a:hover {
	                color:#333 !important;
	            }
	        }
	    }
	}
	
	
	/* 
	 * WIDGET COLOR DROPDOWN
	 * 
	 */
	
	.color-select {
	    list-style:none;
	    margin:0;
	    padding:4px;
	    min-width: 166px;
	    max-width: 156px;
	    right: -3px;
	    li {
	        display:block;
	        margin:2px;
	        float:left;
	        span:hover {
	            border:3px solid rgba(0,0,0,.2);
	        }
	    }
	}
	.color-select li span, .color-box {
	    display:block;
	    width:22px;
	    height:22px;
	    padding:0;
	    background:#333;
	    box-sizing:border-box;
	    -moz-box-sizing:border-box;
	    -webkit-box-sizing:border-box;
	    border:1px solid rgba(0,0,0,.1);
	    cursor:pointer;
	}
	.color-box {
	    position:relative;
	    vertical-align:middle;
	    border:1px solid rgba(255,255,255,.3) !important;
	    outline:1px solid rgba(0,0,0,.1);
	    background: rgba(255,255,255,.3) !important;
	}
	.color-box:hover, .open .color-box {
	    outline:1px solid rgba(0,0,0,.1) !important;
	}
	.color-box:hover {
	    -webkit-transform: scale(1.07);
	    -moz-transform: scale(1.07);
	}
	.color-box:active {
	    top:1px;
	    left:1px;
	}
	.widget-toolbar {
	    & > .color-box {
	        margin-top: 7px;
	        width: 18px;
	        height: 18px;
	        outline: 1px solid rgba(0, 0, 0, 0.2);
	    }
	    .dropdown-menu.color-select {
	        right:-1px;
	    }
	}
	.widget-toolbar &>.color-box:hover,  .widget-toolbar &>.color-box:active, .widget-toolbar &>.color-box:focus {
	    outline: 1px solid rgba(0, 0, 0, 0.25) !important;
	}

	
	/*
	 * ICONS
	 */
	
	.jarviswidget-ctrls a {
		text-decoration:none;
		font-size:14px;
		text-align:center;
		display:inline-block;
		line-height:32px;
		padding:0;
		margin:0;
		color:#333;
	}

	
	/*
	 * RTL - SUPPORT
	 
	
	.rtl .jarviswidget > header {
		padding: 0 7px 0 0;
	}
	.rtl .jarviswidget > header h2 {
		float: right;
		text-align: right
	}
	.rtl .jarviswidget > header .jarviswidget-icon {
		float: right;
		margin: 12px 0 0 7px;
	}
	.rtl .jarviswidget-ctrls {
		float: left;
		padding: 10px 0 0 3px;
		margin: 0;
	}
	.rtl .jarviswidget-ctrls .button-icon {
		margin: 0 0 5px 5px;
	}
	.rtl .jarviswidget-loader {
		float: left;
	}*/
	
	/*
	 * CLEARFIX
	 */
	
	.jarviswidget:before, .jarviswidget:after, .jarviswidget > div:before, .jarviswidget > div:after, .inner-spacer:before, .inner-spacer:after, .jarviswidget-editbox:before, .jarviswidget-editbox:after, .clearfix:before, .clearfix:after {
		content: "\0020";
		display: block;
		height: 0;
		overflow: hidden;
		font-size: 0;
		line-height: 0;
		visibility: hidden;
	}
	.jarviswidget:after, .jarviswidget > div:after, .inner-spacer:after, .jarviswidget-editbox:after, .clearfix:after {
		clear: both;
	}
	.jarviswidget, .jarviswidget > div, .inner-spacer:after, .jarviswidget-editbox, .clearfix {
		zoom: 1;/*IE*/
	}
	
	/*
	 * WIDGET TOOLBARS
	 */
	
	.widget-toolbar {
	    display:inline-block;
	    float:right;
	    width: auto;
	    height: 32px;
	    line-height:32px;
	    position: relative;
	    border-left: 1px solid rgba(0, 0, 0, 0.09);
	    cursor:pointer;
	    padding: 0 8px;
	    text-align:center;
	    & > :first-child {
	        text-align:left;
	    }
	}

	
	/*
	 * WIDGET TOOLBAR COMPONENTS
	 */
	
		/*
		 * TABS
		 */

		.jarviswidget {
		    header {
		        .nav-tabs {
		            border-bottom-color:transparent;
		            & > li {
		                & > a {
		                    border-radius:0px;
		                    border:none;
		                    padding: 7px 15px 6px;
		                }
		            }
		        }
		    }
		    header:first-child {
		        .nav-tabs {
		            float:left;
		            li {
		                padding-left:0;
		                a {
		                    color:#333;
		                }
		            }
		        }
		        .nav-tabs.pull-right {
		            li:last-child {
		                a {
		                    margin-right:0px !important;
		                    border-right:none !important;
		                }
		            }
		        }
		    }
		}
		.jarviswidget header .nav-tabs &>li.active &>a, .jarviswidget header .nav-tabs &>li.active &>a:hover,.jarviswidget header .nav-tabs &>li.active &>a:focus {
		    color: #555;
		    background-color: #FFF;
		    border: 1px solid #C2C2C2;
		    border-bottom-color: transparent;
		    border-top:none;
		    cursor: default;
		}
		
		/*
		 * PILLS
		 */

		.jarviswidget {
		    header {
		        .nav-pills {
		            margin:3px;
		            & > li {
		                & > a {
		                    padding: 3px 5px 4px;
		                }
		            }
		        }
		    }
		}
		
		/*
		 * BTN GROUPS
		 */

		.widget-toolbar {
		    .btn-group {
		        margin-top:-3px;
		    }
		}
	
		/*
		 * BTNs
		 */
		
		.widget-toolbar {
		    & > .btn {
		        margin-top:-3px;
		        font-size:12px !important;
		        padding: 1px 8px !important;
		    }
		}
		
		/*
		 * INPUT
		 */
		
		.widget-toolbar .smart-form label.checkbox, 
		.widget-toolbar .smart-form label.radio {
			line-height:29px;
		}
		
		/*
		 * LABELS
		 */
		.widget-toolbar {
		    & > .label {
		        vertical-align: middle;
		        margin-top: -3px;
		        display: inline-block;
		        text-align:center;
		        font-size:12px;
		        padding: 4px 7px;
		    }
		}

		
		/*
		 * BADGE
		 */
		
		.widget-toolbar {
		    & > .badge {
		        padding: 5px 5px;
		        font-size: 14px;
		        border-radius: 50%;
		        font-weight:normal;
		        min-width:24px;
		        text-align: center !important;
		    }
		}
		
		/*
		 * PROGRESS BARS
		 */
		
		.widget-toolbar {
		    .progress {
		        width:130px;
		        margin: 7px 0 0 0;
		        height: 18px !important;
		        font-size:12px;
		        box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #D1D1D1 inset;
		        -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #D1D1D1 inset;
		        -moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px #D1D1D1 inset;
		    }
		    .progress-bar {
		        font-size:12px;
		    }
		}

		
		/*
		 * PAGINATION
		 */
		
		.widget-toolbar {
		    .pagination {
		        margin:4px 0;
		        & > li {
		            & > a {
		                padding: 2px 7px;
		            }
		        }
		    }
		}


		/*
		 * TEXT INPUT
		 */
		
		.widget-toolbar {
		    .widget-text-input {
		        max-width:220px;
		    }
		    input[type='text'] {
		        height:28px !important;
		        margin-top:2px;
		    }
		}
		.widget-toolbar.smart-form .icon-append, .widget-toolbar.smart-form .icon-prepend,.widget-toolbar .smart-form .icon-append, .widget-toolbar .smart-form .icon-prepend {
		    top:3px !important;
		}


/*
 * Enhancements for other plugins 
 */

 // full calendar
 #jarviswidget-fullscreen-mode .fc-grid .fc-day-number {
 	padding: 5px 12px;
	font-size: 40px;
 }