.bootstrap-duallistbox-container {
    .buttons {
        width: 100%;
        margin-bottom: -1px;
    }
    label {
        display: block;
        margin-top:0px !important;
        font-weight: 400 !important;
    }
    .info {
        display: inline-block;
        margin-bottom: 5px;
        font-size: 11px;
    }
    .btn-group {
        .btn {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    select {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        width: 100%;
        height: 300px;
        padding: 0;
    }
    .filter {
        display: inline-block;
        width: 100%;
        height: 31px;
        margin: 0 0 5px 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .filter.placeholder {
        color: #aaa;
    }
}
.bootstrap-duallistbox-container .clear1,.bootstrap-duallistbox-container .clear2 {
    display: none;
    font-size: 10px;
}
.bootstrap-duallistbox-container .box1.filtered .clear1,.bootstrap-duallistbox-container .box2.filtered .clear2 {
    display: inline-block;
}
.bootstrap-duallistbox-container .move,.bootstrap-duallistbox-container .remove {
    width: 60%;
}
.bootstrap-duallistbox-container .moveall,.bootstrap-duallistbox-container .removeall {
    width: 40%;
}
.bootstrap-duallistbox-container.bs2compatible .btn-group > .btn + .btn {
  margin-left: 0;
}
.bootstrap-duallistbox-container.moveonselect .move,.bootstrap-duallistbox-container.moveonselect .remove {
    display:none;
}
.bootstrap-duallistbox-container.moveonselect .moveall,.bootstrap-duallistbox-container.moveonselect .removeall {
    width: 100%;
}
