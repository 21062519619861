/*
 * NESTABLES
 */

.dd {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	max-width: 600px;
	list-style: none;
	font-size: 13px;
	line-height: 20px;
}

.dd-list {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
}
.dd-list .dd-list {
	padding-left: 30px;
}
.dd-collapsed .dd-list {
	display: none;
}

.dd-item, .dd-empty, .dd-placeholder {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	min-height: 20px;
	font-size: 13px;
	line-height: 20px;
}

.dd-handle {
	display: block;
	font-size: 15px;
	margin: 5px 0;
	padding: 7px 15px;
	color: @gray-dark;
	text-decoration: none;
	border: 1px solid lighten(@gray-mid-light, 6%);
	background: lighten( @gray-lightest, 3%);
}
.dd-handle:hover {
	color: #2ea8e5;
	background: @white;
}

.dd-item > button {
	position: relative;
	cursor: pointer;
	float: left;
	width: 25px;
	height: 20px;
	margin: 7px 10px;
	line-height: 22px !important;
	padding: 0;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	border: 0;
	background: rgba(red(@black), green(@black), blue(@black), 0);
	text-align: center;
}
.dd-item > button {
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 18px;
}
.dd-item > button:before {
	content: '\f055';
	display: block;
	position: absolute;
	width: 100%;
	text-align: center;
	text-indent: 0;
	color:@blueSky;
}
.dd-item > button[data-action="collapse"]:before {
	content: '\f056';
	color: @brand-danger;
}

.dd-placeholder, .dd-empty {
	margin: 5px 0;
	padding: 0;
	min-height: 30px;
	background: #f2fbff;
	border: 1px dashed #b6bcbf;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}
.dd-empty {
	border: 1px dashed #bbb;
	min-height: 100px;
	background-color: @gray-lighter;
	background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
	background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
	background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
	background-size: 60px 60px;
	background-position: 0 0, 30px 30px;
}

.dd-dragel {
	position: absolute;
	pointer-events: none;
	z-index: 9999;
}
.dd-dragel > .dd-item .dd-handle {
	margin-top: 0;
}
.dd-dragel .dd-handle {
	-webkit-box-shadow: 2px 4px 6px 0 rgba(red(@black), green(@black), blue(@black),.1);
	box-shadow: 2px 4px 6px 0 rgba(red(@black), green(@black), blue(@black),.1);
}

/**
 * Nestable Extras
 */

.nestable-lists {
	display: block;
	clear: both;
	padding: 30px 0;
	width: 100%;
	border: 0;
	border-top: 2px solid @gray-mid-light;
	border-bottom: 2px solid @gray-mid-light;
}

#nestable-menu {
	padding: 0;
	margin: 10px 0 20px;
}

#nestable-output, #nestable2-output {
	width: 100%;
	padding: 5px;
}

@media only screen and (min-width: 700px) {

	.dd {
		float: left;
		width: 100%;
	}
	.dd + .dd {
		margin-left: 2%;
	}

}

.dd-hover > .dd-handle {
	background: #2ea8e5 !important;
}

/**
 * Nestable Draggable Handles
 */

.dd3-content {
	display: block;
	margin: 5px 0;
	padding: 6px 10px 8px 40px;
	font-size: 15px;
	color: @gray-dark;
	text-decoration: none;
	border: 1px solid lighten(@gray-mid-light, 6%);
	background: lighten( @gray-lightest, 3%);
}
.dd3-content:hover {
	color: #2ea8e5;
	background: @white;
}

.dd-dragel > .dd3-item > .dd3-content {
	margin: 0;
}

.dd3-item > button {
	margin-left: 38px;
}

.dd3-handle {
	position: absolute;
	margin: 0;
	left: 0;
	top: 0;
	cursor: move;
	width: 30px;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	border: 1px solid #aaa;
	background: @gray-mid-light;
	background: -webkit-linear-gradient(top, darken(@gray-lighter, 7%) 0%, #bbb 100%);
	background: -moz-linear-gradient(top, darken(@gray-lighter, 7%) 0%, #bbb 100%);
	background: linear-gradient(top, darken(@gray-lighter, 7%) 0%, #bbb 100%);
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 18px;
	padding: 8px 15px;
}
.dd3-handle:before {
	content: '\f0c9';
	display: block;
	position: absolute;
	left: 0;
	top: 3px;
	width: 100%;
	text-align: center;
	text-indent: 0;
	color: #818181;
	font-weight: normal;
	line-height: 28px;
}
.dd3-handle:hover:before {
	color: #C27616;
}

.dd-handle:hover + .dd-list .dd-handle, .dd-handle:hover {
	background: #FDDFB3 !important;
	border: 1px solid #FAA937;
	color: #333 !important;
}

.dd-handle > span {
	font-size: 13px;
	color: #777;
}
.dd-dragel > .dd-item > .dd-handle {
	border-left-width: 4px;
}

.dd-dragel > li.dd-item.dd3-item {
	position: relative;
	top: 10px;
	left: 10px;
	z-index: 999;
}