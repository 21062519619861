/*
  ############################################
  #     START FORMCONTROL STYLES             #
  ############################################
*/

.input-text .form-control-feedback {
  position: absolute !important;
  right: 0px !important;
  left: auto !important;
  top: auto !important;
  bottom: 17px !important;
}

.selectpicker-container .form-control-feedback {
    /* Adjust feedback icon position */
    right: -32px;
    }

.top .form-control-feedback {
    /* Adjust feedback icon position */
    top:0px;
    }

.select-container .form-control-feedback {
    /* Adjust feedback icon position */
    right: -27px;
    }

.checkbox-container .form-control-feedback {
    /* Adjust feedback icon position */
    top: -8px;
    }

.hide-validation-icon .form-control-feedback {
    /* Adjust feedback icon position */
    display: none !important;
    }

/* START UPLOAD BTN */
.btn-file {
    position: relative;
    overflow: hidden;
    }
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
    }
.color-red
{
    color: #bb1916 !important;
    }

/* END UPLOAD BTN */

/* START views FormControl::dropzone_file, FormControl::dropzone_img */

.container-dropzone-files li
{
    position: relative;
    }
.container-dropzone-files li label{
    position: absolute;
    bottom: 20px;
    right: 12px;
    z-index: 2;
    cursor: pointer;
    color: #d43d32;
    background-color: #ffffff;
    border-radius: 50px;
    width: 22px;
    text-align: center;
    height: 22px;
    padding: 2px;
    opacity:0.6;
    }

.container-dropzone-files li label:hover{
    opacity: 1;
    }

.sortable-placeholder
{
    background: #ffffe0;
    border: 1px darkslategray dashed;
    border-radius: 5px;
    margin-bottom: 18px;
    }
.container-dropzone-files
{
    margin-top:10px;
    }

.container-dropzone-files li label.dropzone-files-trash
{
    top:0px;
    right:0px;
    }

.container-dropzone-files li.dropzone-li-file-container
{
    border: 1px dashed rgba(0, 0, 0, 0.2);
    padding: 15px 10px 10px 10px;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
    }
/* END views FormControl::dropzone_file, FormControl::dropzone_img */

