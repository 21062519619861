	/* The styles given to the associated form element in order to hide it */
	.fd-form-element-hidden {
		display: none;
	}
	/* Horizontal Outer wrapper - all other DOM elements added as children to this top level wrapper */
	.fd-slider {
		width: 100%;
		/* The height of the slider handle */
		height: 20px;
		margin: 0;
	}
	/* Vertical Outer wrapper - all other DOM elements added as children to this top level wrapper */
	.fd-slider-vertical {
		/* The width of the slider handle */
		width: 20px;
		/* Fill the available space */
		height: 100%;
		/* Set a 10 pixel right and bottom margin */
		margin: 0 10px 10px 0;
		/* You may wish to float the vertical sliders left or display:inline-block */
		/* float:left; */
	}
	/* Shared rules - both horizontal & vertical sliders */
	.fd-slider, .fd-slider-vertical {
		/* display:block required as the wrapper element is a span */
		display: block;
		/* This lets us absolutely position the drag handle */
		position: relative;
		text-decoration: none;
		border: 0 none;
		-moz-user-select: none;
		-khtml-user-select: none;
		-webkit-touch-callout: none;
		user-select: none;
	}
	.fd-slider-inner {
		/* Used by IE for the onfocus blur effect */
		display: none;
	}
	
	/* The inner track bar */
	.fd-slider-bar {
		position: absolute;
		display: block;
		z-index: 2;
		height: 6px;
		width: 100%;
		border: 1px solid #bbb;
		border-bottom: 1px solid #fff;
		border-right: 1px solid #fff;
		margin: 0;
		padding: 0;
		overflow: hidden;
		line-height: 4px;
		top: 8px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		border-radius: 4px;
		-moz-background-clip: padding;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		background-color: #333;
	}
	/* The animated range bar */
	.fd-slider-range {
		position: absolute;
		display: block;
		z-index: 3;
		height: 6px;
		margin: 0;
		padding: 0 2px 0 0;
		overflow: hidden;
		top: 9px;
		-moz-border-radius: 2px;
		-webkit-border-radius: 2px;
		border-radius: 2px;
		-moz-background-clip: padding;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		background-color: #eee;
	}
	/* The drag handle */
	.fd-slider-handle {
		position: absolute;
		display: block;
		padding: 0;
		border: 0 none;
		margin: 0 0 0 1px;
		z-index: 3;
		top: 5px;
		left: 0;
		width: 12px;
		height: 12px;
		line-height: 1px !important;
		outline: 0 none;
		background: #eee;
		border: 1px solid #aaa;
		border-radius: 12px;
		-webkit-user-select: none;
		-webkit-touch-callout: none;
		-moz-user-select: none;
		-moz-user-focus: none;
		-moz-outline: 0 none;
		user-select: none;
	}
	/* Focus styles */
	.fd-slider-handle:focus {
		outline: 0 none;
		border: 0 none;
		-moz-user-focus: normal;
	}
	button.fd-slider-handle:focus::-moz-focus-inner {
		border-color: transparent;
	}
	
	body.fd-slider-drag-vertical, body.fd-slider-drag-vertical * {
		/* Stop text selection */
		-moz-user-select: none;
		-webkit-user-select: none;
		user-select: none;
	}
	body.fd-slider-drag-horizontal, body.fd-slider-drag-horizontal * {
		/* Stop text selection */
		-moz-user-select: none;
		-webkit-user-select: none;
		user-select: none;
	}
	
	/*
	 Sample tooltip code. Only works on grade A browsers (so no IE6,7 or 8).
	
	 See: http://nicolasgallagher.com/multiple-backgrounds-and-borders-with-css2/ for full info on
	 how to style generated content & the associated pitfalls
	
	 This code to be taken as experimental & untested - use at your discretion
	
	 If showing the tooltip above the sider handle you are relegated to showing
	 single line tooltips due to styling constraints!
	 */
	
	.fd-slider-handle:before, .fd-slider-handle:after {
		/* Remove from screen */
		opacity: 0;
		/* The following rules are not really needed as no browser yet supports CSS transitions
		 on generated content but I'll leave it in for the day when they do! */
	
		/* Firefox */
		-moz-transition-property: opacity;
		-moz-transition-duration: 1s;
		-moz-transition-delay: 1s;
		/* WebKit */
		-webkit-transition-property: opacity;
		-webkit-transition-duration: 1s;
		-webkit-transition-delay: 1s;
		/* Opera */
		-o-transition-property: opacity;
		-o-transition-duration: 1s;
		-o-transition-delay: 1s;
		/* Standard */
		transition-property: opacity;
		transition-duration: 1s;
		transition-delay: 1s;
	}
	/*
	 The tooltip body - as we position it above the slider and position the tooltip arrow
	 below it, we need to know the height of the body. This means that multi-line tooltips
	 are not supported.
	
	 To support multi-line tooltips, you will need to position the tooltip below the slider
	 and the tooltip pointer above the tooltip body. Additionally, you will have to set the
	 tooltip bodies "height" to auto
	 */
	.fd-slider-focused .fd-slider-handle:before, .fd-slider-hover   .fd-slider-handle:before, .fd-slider-active  .fd-slider-handle:before {
		display: block;
		position: absolute;
		top: -21px;
		left: -8px;
		margin: 0;
		width: 20px;
		padding: 3px;
		height: 14px;
		line-height: 12px;
		text-align: center;
		font-size: 10px;
		font-weight: bold;
		color: #fff;
		text-shadow: 1px 1px 1px #1a3a95;
		background: #2f6ee0;
		z-index: 1;
		/* Use the ARIA valuetext property, set by the script, to generate the tooltip content */
		content: attr(aria-valuetext);
		/* Border radius and box shadow */
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		-moz-background-clip: padding;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		-moz-box-shadow: 0 0 4px #aaa;
		-webkit-box-shadow: 0 0 4px #aaa;
		box-shadow: 0px 0px 4px #999;
		/* Change opacity for transition */
		opacity: 1;
	}
	/* The tooltip pointer */
	.fd-slider-focused .fd-slider-handle:after, .fd-slider-hover   .fd-slider-handle:after, .fd-slider-active  .fd-slider-handle:after {
		outline: none;
		content: "";
		display: block;
		position: absolute;
		top: -9px;
		left: 50%;
		margin: 0 0 0 -5px;
		background: #2f6ee0;
		z-index: 2;
		width: 10px;
		height: 10px;
		overflow: hidden;
		/* Rotate element by 45 degress to get the "\/" pointer effect */
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		/* Add a box shadow */
		-moz-box-shadow: 0 0 4px #aaa;
		-webkit-box-shadow: 0 0 4px #aaa;
		box-shadow: 0 0 4px #aaa;
		/* Clip */
		clip: rect(4px, 14px, 14px, 4px);
		/* Change opacity for transition */
		opacity: 1;
	}
	/* Remove completely for IE */
	.oldie .fd-slider-handle:before, .oldie .fd-slider-handle:after {
		display: none;
	}      